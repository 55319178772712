import React from 'react';

import 'assets/styles/StepsContainer.scss';
import { step1, step2, step3 } from 'assets/images';
import { Translate } from 'hooks/Translation';

function StepsContainer() {
  const { t } = Translate();

  return (
    <>
      <div className="steps-container ">
        <div className="title medium ">
          <span className="subBlueTitle">{t('step_intro_1')}</span>
          {t('step_intro_2')}
        </div>
        <div className="steps d-flex flex-wrap">
          <div className="step-box col-6 col-lg-4">
            <img src={step1} alt="mobile" />
            <div className="description">
              <span className="medium subBlueTitle">{t('step_1')}</span>
              <br />
              <span className="medium subBlueTitle">{t('step_title_1')}</span>
              <div className="info">{t('step_info_1')}</div>
            </div>
          </div>
          <div className="step-box col-6 col-lg-4">
            <img src={step2} alt="mobile" />
            <div className="description">
              <span className="medium subBlueTitle">{t('step_2')}</span>
              <br />
              <span className="medium subBlueTitle">{t('step_title_2')}</span>
              <div className="info">{t('step_info_2')}</div>
            </div>
          </div>
          <div className="step-box col-6 mt-4 mt-lg-0 col-lg-4">
            <img src={step3} alt="mobile" />
            <div className="description">
              <span className="medium subBlueTitle">{t('step_3')}</span>
              <br />
              <span className="medium subBlueTitle">{t('step_title_3')}</span>
              <div className="info">{t('step_info_3')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepsContainer;
