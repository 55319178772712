import React from 'react';

export const faq = [
  {
    title: 'faq_1',
    description: 'faq_ans_1'
  },
  {
    title: 'faq_2',
    description: 'faq_ans_2'
  },
  {
    title: 'faq_3',
    description: 'faq_ans_3'
  },
  {
    title: 'faq_4',
    description: 'faq_ans_4'
  },
  {
    title: 'faq_5',
    description: 'faq_ans_5'
  },
  {
    title: 'faq_6',
    description: 'faq_ans_6'
  },
  {
    title: 'faq_7',
    description: 'faq_ans_7'
  },
  {
    title: 'faq_8',
    description: 'faq_ans_8'
  },
  {
    title: 'faq_9',
    description: 'faq_ans_9'
  },
  {
    title: 'faq_10',
    description: 'faq_ans_10',
    hyperText: 'askpos',
    link: 'https://www.pos.com.my/contact-us#AskPos'
  }
];
