import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Check2Circle, ExclamationCircle } from 'react-bootstrap-icons';

import 'assets/styles/Status.Modal.scss';
import DataLayer from 'hooks/DataLayerPush';
import { useStepper } from 'hooks/Stepper';
import { t } from 'i18next';

function StatusModal(props) {
  const { sendPageData } = DataLayer();
  const { moveNext, moveToFirstPage } = useStepper();
  const color = props.type;

  return (
    <>
      <Modal
        show={props.status}
        onHide={props.handleClose}
        className="status-modal"
        backdrop="static"
      >
        <Modal.Header closeButton />
        <Modal.Header>
          <Modal.Title
            className={
              color === 'error'
                ? 'modal-title-red'
                : color === 'success'
                ? 'modal-title-green'
                : 'modal-title-blue'
            }
          >
            <div
              className={
                color === 'error'
                  ? 'icon-red'
                  : color === 'success'
                  ? 'icon-green'
                  : 'icon-blue'
              }
            >
              {color === 'success' ? (
                <Check2Circle size={60} />
              ) : (
                <ExclamationCircle size={60} />
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="status-body">
            <p className="medium message">
              {color === 'success'
                ? t('fl_error_success')
                : color === 'error'
                ? t('fl_error_sorry')
                : color === 'renew'
                ? t('renew1')
                : color === 'notAvailable'
                ? t('renew8')
                : color === 'noMatch'
                ? t('renew5')
                : t('attention')}
            </p>
            {props.message !== '' ? <span>{props.message}</span> : null}
          </div>
          {props.copyMessage && props.paymentStatus !== 'error' && (
            <div className="copyMessage">{props.copyMessage}</div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex flex-column">
          <div style={{ display: 'flex' }}>
            <Button
              variant="secondary"
              className="m-1 button"
              onClick={() => {
                if (color === 'error') {
                  props?.handleClose ? props.handleClose() : moveToFirstPage();
                } else if (color === 'success') {
                  window.open('https://www.pos.com.my/', '_self');
                } else if (color === 'renew') {
                  sendPageData({
                    event: 'road_tax_begin_renewal',
                    event_category: 'Road Tax - Home Renewal Option',
                    event_action: 'Begin Renewal',
                    event_label: 'Renewal - Road Tax'
                  });
                  moveNext();
                } else if (color === 'notAvailable' || color === 'noMatch') {
                  props.handleClose && props?.handleClose();
                  sendPageData({
                    event: 'go_to_page',
                    event_category: 'Road Tax - Home Renewal Error',
                    event_action: 'Go To Page',
                    event_label: 'Home'
                  });
                }
              }}
            >
              {color === 'renew'
                ? t('renew4')
                : color === 'success'
                ? t('fl_error_done')
                : color === 'notAvailable' || color === 'noMatch'
                ? t('try_again')
                : color === 'error'
                ? t('fl_error_close')
                : t('fl_error_close')}
            </Button>
            {color === 'error' ? (
              <Button
                variant="secondary"
                className="m-1 button"
                onClick={props?.handleBack}
              >
                {t('fl_error_retry')}
              </Button>
            ) : null}
          </div>
          <div
            onClick={() => {
              if (color === 'notAvailable' || color === 'renew') {
                sendPageData({
                  event: 'motor_go_to_renewal',
                  event_category: 'Road Tax - Home Renewal Option',
                  event_action: 'Go To Renewal',
                  event_label: 'Renewal - Motor Insurance'
                });
              }
              window.open('https://insurance.pos.com.my');
              props.handleClose();
            }}
            className="refId"
            style={{ fontSize: '14px', cursor: 'pointer' }}
          >
            {color === 'notAvailable' || color === 'noMatch'
              ? t('renew7')
              : color === 'renew'
              ? t('renew3')
              : null}
          </div>
          {props.refId && props.paymentStatus !== 'error' && (
            <>
              <div className="idMessage">{t('your_id')}</div>
              <div className="refId">{props.refId}</div>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StatusModal;
