import React from 'react';

import { style } from './Maintenance.style';
import { useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';

export const Maintenance = () => {
  const { t } = Translate();
  const base = useAppSelector((state) => state.base);
  return (
    <div style={style.container}>
      <div style={style.image}>
        <svg
          width="43"
          height="43"
          viewBox="0 0 43 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.5 12.5417V21.5L17.0208 18.8125M37.625 21.5C37.625 30.4056 30.4056 37.625 21.5 37.625C12.5944 37.625 5.375 30.4056 5.375 21.5C5.375 12.5944 12.5944 5.375 21.5 5.375C30.4056 5.375 37.625 12.5944 37.625 21.5Z"
            stroke="#5698FC"
            strokeWidth="3.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div style={style.title}>{t('maintenance_title')}</div>
      <div>
        {`${t('maintenance_body1')} `}
        <a
          href="https://www.pos.com.my/pos-outlet-finder"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('maintenance_button')}
        </a>
        {`${base.language === 'en' ? '.' : ''} ${t('maintenance_body2')}`}
      </div>
    </div>
  );
};

export default Maintenance;
