import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';

import '../../assets/styles/inputStyles.scss';
import checkCirlce from '../../assets/images/check-circle-gray.svg';
import iconError from '../../assets/images/field-icon-error.svg';
import iconSuccess from '../../assets/images/field-icon-success.svg';
import { ErrorMessage } from '@hookform/error-message';
import { Translate } from 'hooks/Translation';

function InputText(state) {
  const { t } = Translate();
  const [fieldBgColor, setFieldBgColor] = useState('');
  const [fieldIconStatus, setFieldIconStatus] = useState(null);
  const {
    formState: { dirtyFields, errors },
    getValues,
    register,
    setValue
  } = useFormContext({ defaultValues: { [state.register.control]: '' } });

  useEffect(() => {
    if (
      state?.formError ||
      dirtyFields[state?.register?.control] ||
      state.fieldValue ||
      state.defaultValue
    ) {
      if (
        !errors[state?.register?.control] &&
        Boolean(getValues(state?.register?.control))
      ) {
        setFieldBgColor('success');
        setFieldIconStatus(iconSuccess);
      } else if (
        Object.keys(errors)?.length > 0 &&
        errors[state?.register?.control]
      ) {
        setFieldBgColor('error');
        setFieldIconStatus(iconError);
      } else {
        setFieldBgColor('');
        setFieldIconStatus(null);
      }
    }
  }, [state, dirtyFields, errors, getValues]);

  return (
    <Form.Group
      as={Col}
      lg={state?.fieldLg}
      md={state?.fieldMg}
      controlId={state?.fieldControlId}
      className={`field-wrapper form-element-container ${
        state?.noMargin ? 'mb-2' : ''
      }`}
    >
      <InputGroup>
        <FloatingLabel
          controlId={state?.floatingControlId}
          label={state?.floatingLabel}
        >
          {state.show && (
            <span className="countryCode">{state.countryCode}</span>
          )}
          <Form.Control
            maxLength={state.maxLength}
            readOnly={state?.read}
            type={state?.fieldType}
            placeholder={state?.fieldPlaceholder}
            className={`${fieldBgColor} ${state?.countryClass} ${state.fieldControlId}`}
            disabled={state?.disabled}
            defaultValue={state?.defaultValue || state?.fieldValue}
            onChange={(e) => {
              let value = e.target.value;
              if (state?.register?.rules?.trim) value = value.replace(' ', '');
              if (state?.register?.rules?.uppercase) {
                value = value.toUpperCase();
              }
              setValue(state?.register?.control, value);
            }}
            onSelect={(e) => {
              let value = e.target.value;
              state?.detectEmpty(value);
              if (state?.register?.rules?.trim) value = value.replace(' ', '');
              if (state?.register?.rules?.no0) {
                if (value.charAt(0) === '0') {
                  value = value.charAt(0).replace('0', '');
                }
              }
              if (state?.register?.rules?.uppercase) {
                value = value.toUpperCase();
              }
              setValue(state?.register?.control, value);
            }}
            {...register(
              state.register.control,
              state.register.rules,
              state.register.maxLength
            )}
          />
        </FloatingLabel>
        {fieldIconStatus && (
          <img className="icon" src={`${fieldIconStatus}`} alt="icon-error" />
        )}
        {state.validate && (
          <img
            className="icon checkCircleGray"
            src={checkCirlce}
            alt="icon-error"
          />
        )}
      </InputGroup>
      <ErrorMessage
        errors={errors}
        name={state?.register?.control}
        render={({ messages }) => {
          return (
            <span className="error-label">
              {t(errors[state?.register?.control].message)}
            </span>
          );
        }}
      />
    </Form.Group>
  );
}

export default InputText;
