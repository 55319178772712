export const containerColor = {
  blue: 'blueBg',
  lightBlue: 'lblueBg'
};

export const content = {
  car: 'car_insurance_renewal',
  motorcycle: 'motor_insurance_renewal'
};

export const vehicleTypeContent = {
  car: 'drive_car',
  motorcycle: 'drive_motor'
};

export const activeBtn = {
  car: 'car-active motor-inactive',
  motorcycle: 'motor-active car-inactive'
};
