export const roadTaxRequest = ({ vix }, formData, carType, price, email) => {
  return {
    regNo: formData?.regNo,
    bankAppCode: '',
    ccardExpdt: '',
    ccardType: '',
    email,
    icNumber: formData?.newIC,
    passportNo: formData?.passportNo,
    name: formData?.fullName,
    payAmt: price?.price,
    payType: '',
    phoneNo: `60${formData.mobileNumber}`,
    serviceTaxAmt: price?.serviceFee,
    totalAmt: price?.totalPrice,
    variantName: vix?.variation?.[0]?.desc || vix?.data?.vehName,
    vehBody: carType?.['Vehicle Type'] || 'Saloon',
    vehBodyCode: vix?.variation?.[0]?.model || '',
    vehCapaCity: vix?.vehCapaCity || vix?.data?.vehCapacity,
    vehCapacityCode: 'CC',
    vehTypeCode: vix?.vehTypeCode || vix?.data?.vehTypeCode || '',
    remark: ''
  };
};

export const initPaymentReq = (price, vehicleForm, { roadtax }) => {
  return {
    payment_gateway: 'kiplepay',
    application_name: 'roadtax',
    return_url: `${process.env.REACT_APP_PGW_URL}/process_payment`,
    redirect_success_url: `${process.env.REACT_APP_BASEURL}/payment-status?status=success`,
    redirect_failure_url: `${process.env.REACT_APP_BASEURL}/payment-status?status=error`,
    redirect_abort_url: vehicleForm?.regNo,
    order_total_amount: `${price?.totalPrice}`,
    order_gst_amount: '0',
    order_ship_name: vehicleForm?.fullName,
    order_ship_country: 'Malaysia',
    order_telephone: `+60${vehicleForm?.mobileNumber}`,
    order_email: vehicleForm?.email,
    order_delivery_charges: '0',
    order_service_charges: '0',
    order_reference: roadtax?.id,
    generate_html_form: 'true',
    dynamic_callback_url: `${process.env.REACT_APP_AWS_URL}payment/callback-url`
  };
};

export const vixNcd = (vixData) => {
  return {
    regNo: vixData?.regNo,
    newIc: vixData?.newIc,
    passportNo: vixData?.passportNo,
    compCode: 'POS'
  };
};
