import React from 'react';

import './InsuranceRenewal.style.scss';
import { CarTypeContainer } from 'components/SubComponents';
import useMobileSize from 'hooks/IsMobile';
import { Translate } from 'hooks/Translation';

const InsuranceRenewal = ({ img }) => {
  const { isMobileView } = useMobileSize();
  const { t } = Translate();
  return (
    <div className="advantage-container d-flex flex-column flex-lg-row flex-wrap mt-5">
      {isMobileView ? (
        <div className="">
          <div className="insurance-renewal">
            <div className="title-insurance medium dark mb-2">
              <div>{t('car_type_1')} </div>
              <span className="light medium">{t('car_type_2')}</span>
              <span className="dark">?</span>
            </div>
            <div className="subtitle-insurance">{t('car_type_3')}</div>
          </div>
          <div className="rtax-img">
            <img src={img} alt="road-tax" />
          </div>
          <div className="insurance-renewal">
            <CarTypeContainer
              color={'blue'}
              type={'car'}
              infoButton={true}
              url={'https://insurance.pos.com.my/'}
            />
            <CarTypeContainer
              color={'blue'}
              type={'motorcycle'}
              infoButton={true}
              url={'https://insurance.pos.com.my/'}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="advantage col-12 col-sm-12 col-lg-6 col-xl-6 p-sm-0 px-sm-4 pt-sm-3 p-4">
            <div className="insurance-renewal">
              <div className="title-insurance medium dark mb-2">
                <div>{t('car_type_1')} </div>
                <span className="light medium">{t('car_type_2')}</span>
                <span className="dark">?</span>
              </div>
              <div className="subtitle-insurance">{t('car_type_3')}</div>
              <CarTypeContainer
                color={'blue'}
                type={'car'}
                infoButton={true}
                url={'https://insurance.pos.com.my/'}
              />
              <CarTypeContainer
                color={'blue'}
                type={'motorcycle'}
                infoButton={true}
                url={'https://insurance.pos.com.my/'}
              />
            </div>
          </div>
          <div className="rtax-img col-sm-12 col-lg-6 col-xl-6">
            <img src={img} alt="road-tax" />
          </div>
        </>
      )}
    </div>
  );
};

export default InsuranceRenewal;
