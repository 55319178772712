import useSaveQueryParams from './SaveQueryParams';
import CryptoJS from 'crypto-js';

const useResumeJourney = () => {
  const { getAllQueryStrings } = useSaveQueryParams();
  const queryParams = getAllQueryStrings();
  const { data, status } = queryParams;
  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES?.decrypt(
      encryptedData,
      process.env.REACT_APP_SECRET_KEY
    );
    const decryptedData = bytes?.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  };
  return data
    ? { vehicleData: decryptData(decodeURIComponent(data)), status }
    : { vehicleData: null, status: null };
};

export default useResumeJourney;
