import React from 'react';

import '../../assets/styles/CheckBoxContainer.scss';
import { t } from 'i18next';

function CheckboxContainer({
  checkComplete = undefined,
  children,
  handleChange,
  info,
  isChecked,
  showTitle = true,
  style,
  title
}) {
  return (
    <label
      className={`checkbox-container ${
        checkComplete === false ? 'incomplete' : ''
      } ${isChecked ? 'selected' : ''}`}
      style={style}
      htmlFor={title}
    >
      <input
        className="form-check-input"
        type="checkbox"
        id={title}
        name={title}
        onChange={(e) => handleChange({ title, checked: e.target.checked })}
        checked={isChecked}
      />
      <div>
        {showTitle && t(title)}
        <div className="info">{info || children}</div>
      </div>
    </label>
  );
}

export default CheckboxContainer;
