import React, { Fragment } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';

import { Translate } from 'hooks/Translation';

export const PosAdvantage = () => {
  const { t } = Translate();
  return (
    <Fragment>
      <div>
        <p>{t('pos_advantage')}</p>
        <div className="bott-bar"></div>
        <h1 className="adv-title-1 bold">
          <span className="subBlueTitle">{t('advantage_title_1')} </span>{' '}
          {t('advantage_title_1_2')}
          <div>{t('advantage_title_1_3')}</div>
        </h1>
        <p className="adv-title-2">{t('advantage_title_3')}</p>
      </div>
      <div className="adv-list d-flex flex-wrap mb-3">
        <div className="d-flex col-lg-6 col-sm-12 col-12">
          <CheckCircleFill className="check m-1" size={20} />
          <p className="medium">{t('advantage_list_1')}</p>
        </div>
        <div className="d-flex col-lg-6 col-sm-12 col-12">
          <CheckCircleFill className="check m-1" size={20} />
          <p className="medium">{t('advantage_list_2')}</p>
        </div>
        <div className="d-flex col-lg-6 col-sm-12 col-12">
          <CheckCircleFill className="check m-1" size={20} />
          <p className="medium">{t('advantage_list_3')}</p>
        </div>
        <div className="d-flex col-lg-6 col-sm-12 col-12">
          <CheckCircleFill className="check m-1" size={20} />
          <p className="medium">{t('advantage_list_4')}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default PosAdvantage;
