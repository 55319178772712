import React from 'react';

import './InfoWithImageContainer.style.scss';
import { Translate } from 'hooks/Translation';

export const InfoWithImageContainer = ({ children, img }) => {
  const { t } = Translate();

  return (
    <>
      <div className="advantage-container d-flex flex-column flex-lg-row flex-wrap mt-5">
        <div className="advantage col-12 col-sm-12 col-lg-6 col-xl-6 p-sm-0 px-sm-4 pt-sm-3 p-4">
          {children}
        </div>
        <div className="rtax-img col-sm-12 col-lg-6 col-xl-6">
          <img src={img} alt="road-tax" />
        </div>
      </div>
    </>
  );
};

export default InfoWithImageContainer;
