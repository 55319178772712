/* eslint-disable no-const-assign */
import React from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FormProvider } from 'react-hook-form';

import 'assets/styles/VehicleForm.scss';
import { VehicleFormLogic } from './Logic/VehicleForm.logic';
import { StatusModal } from 'components/Elements';
import { InputText } from 'components/Fields';
import { regex } from 'constants/regexs';
import { Translate } from 'hooks/Translation';

export const VehicleForm = ({ isOpen }) => {
  const {
    divRef,
    formError,
    isLoading,
    language,
    onError,
    onSubmit,
    setEmptyMobile,
    setStatus,
    show,
    showCode,
    status,
    useFormMethod,
    vehicleForm
  } = VehicleFormLogic();

  const { t } = Translate();

  return (
    <>
      {isOpen ? (
        <div
          id="section2"
          className={
            'vehicle-form d-flex flex-column vhlBg pt-4 pt-md-5 px-4 px-md-5 pb-5'
          }
        >
          <h5 className="mb-4">{t('v_f_title')}</h5>
          <FormProvider {...useFormMethod}>
            <Form onSubmit={useFormMethod.handleSubmit(onSubmit, onError)}>
              <Row className="mb-3">
                <InputText
                  language={language}
                  fieldLg="4"
                  fieldMg="6"
                  detectEmpty={() => null}
                  maxLength="20"
                  fieldControlId="validationCustom01"
                  floatingControlId="floatingInput"
                  floatingLabel={t('v_f_reg_no')}
                  fieldType="text"
                  fieldPlaceholder={t('v_f_reg_no')}
                  formError={formError}
                  defaultValue={vehicleForm.regNo}
                  register={{
                    control: 'regNo',
                    rules: {
                      pattern: {
                        value: /^[A-Za-z][A-Za-z0-9]{0,19}$/,
                        message: 'v_f_reg_no_error_1'
                      },
                      trim: true,
                      uppercase: true,
                      required: 'v_f_reg_no_error_2',
                      maxLength: {
                        value: 20,
                        message: 'v_f_reg_no_error_3'
                      },
                      minLength: {
                        value: 2,
                        message: 'v_f_reg_no_error_4'
                      }
                    }
                  }}
                ></InputText>
                <InputText
                  fieldLg="4"
                  fieldMg="6"
                  detectEmpty={() => null}
                  fieldControlId="validationCustom03"
                  floatingControlId="floatingInput"
                  floatingLabel={t('v_f_ic_num')}
                  fieldType={'text'}
                  fieldPlaceholder={t('v_f_ic_num')}
                  formError={formError}
                  maxLength={'12'}
                  defaultValue={vehicleForm.newIC}
                  register={{
                    control: 'newIc',
                    rules: {
                      required: 'v_f_ic_error_1',
                      pattern: {
                        value: regex.IC_NUMBER,
                        message: 'v_f_ic_error_2'
                      },
                      maxLength: {
                        value: 12,
                        message: 'v_f_ic_error_3'
                      },
                      minLength: {
                        value: 12,
                        message: 'v_f_ic_error_4'
                      }
                    }
                  }}
                ></InputText>
                <InputText
                  fieldLg="4"
                  fieldMg="6"
                  detectEmpty={() => null}
                  maxLength="40"
                  fieldControlId="validationCustom01"
                  floatingControlId="floatingInput"
                  floatingLabel={t('full_name')}
                  fieldType="text"
                  fieldPlaceholder={t('full_name')}
                  formError={formError}
                  defaultValue={vehicleForm.fullName}
                  register={{
                    control: 'fullName',
                    rules: {
                      uppercase: true,
                      required: 'enter_full_name'
                    }
                  }}
                ></InputText>
                <div
                  className="col-lg-4 col-md-6"
                  onChange={(event) => {
                    event.target.value.length === 0
                      ? setEmptyMobile(2)
                      : setEmptyMobile(1);
                  }}
                  onClick={showCode}
                  ref={divRef}
                >
                  <InputText
                    showCountryCode={true}
                    fieldLg="12"
                    maxLength="10"
                    show={show}
                    detectEmpty={() => null}
                    countryClass="countryClass"
                    fieldControlId="validationCustom01"
                    floatingControlId="floatingInput"
                    floatingLabel={t('v_f_mobile')}
                    fieldType="text"
                    fieldPlaceholder={t('v_f_mobile')}
                    countryCode="+60"
                    formError={formError}
                    defaultValue={vehicleForm.mobileNumber}
                    register={{
                      control: 'mobileNumber',
                      rules: {
                        required: 'v_f_mobile_error_1',
                        pattern: {
                          value: regex.MOBILE_NUMBER,
                          message: 'v_f_mobile_error_2'
                        },
                        minLength: {
                          value: 9,
                          message: 'v_f_mobile_error_3'
                        },
                        maxLength: {
                          value: 10,
                          message: 'v_f_mobile_error_4'
                        },
                        no0: true,
                        trim: true
                      }
                    }}
                  ></InputText>
                </div>
              </Row>
              <Row>
                <div className="next-button-container footer d-flex justify-content-center justify-content-lg-end flex-wrap flex-xl-row flex-column">
                  <div className="d-flex flex-column-reverse flex-xl-row mt-xl-0">
                    <div className="desc mt-2 d-flex flex-column align-items-center align-items-xl-end">
                      <div className="label">{t('v_f_b_label_1')}</div>
                      <div className="label">
                        {t('v_f_b_label_2')}
                        <span className="highlight subBlueTitle">
                          <a
                            href={process.env.REACT_APP_TERMS_CONDITION}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('v_f_b_label_3')}
                          </a>
                        </span>
                        <span className="highlight">and</span>
                        <span className="highlight subBlueTitle">
                          <a
                            href={
                              'https://www.pos.com.my/legal/privacy-statement'
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('v_f_b_label_4')}
                          </a>
                        </span>
                        .
                      </div>
                    </div>
                    <div className="d-flex justify-content-center next-button-wrapper">
                      <Button
                        type="submit"
                        className="button-next hover"
                        id="vehicleForm"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          t('v_f_b')
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </Form>
          </FormProvider>
          {status && (
            <StatusModal
              status={status}
              handleClose={() => setStatus(false)}
              type={status}
              message={
                status === 'renew'
                  ? t('renew2')
                  : status === 'notAvailable'
                  ? t('try_again_insured')
                  : null
              }
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default VehicleForm;
