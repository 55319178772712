import React from 'react';

import './CarTypeContainer.style.scss';
import {
  activeBtn,
  containerColor,
  content,
  vehicleTypeContent
} from './Constant';
import { car, motor } from 'assets/images';
import DataLayer from 'hooks/DataLayerPush';
import { useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';
import { capitalizeFirstLetter } from 'utils/helper';

export const CarTypeContainer = ({
  color,
  infoButton = undefined,
  nextClick,
  nonClickable,
  type,
  url,
  vehicleInfo
}) => {
  const { t } = Translate();
  const vhlType = useAppSelector((state) => state.vhlType);
  const vehicleForm = useAppSelector((state) => state.vehicleForm);
  const { sendEventData } = DataLayer();

  const image = {
    car,
    motorcycle: motor
  };

  const redirect = (url) => {
    window.open(url, '_blank');
  };

  const isActive = vhlType.VEHICLE_TYPE === type;

  return (
    <div
      className={`vehicleBox ${containerColor[color]} mb-2 ${
        nonClickable ? 'non-hover' : 'hover'
      } ${!infoButton && isActive ? activeBtn[type] : ''}`}
      data-event={infoButton ? 'quick_menu' : 'select_vehicle_type'}
      data-event-category={'Road Tax - Home'}
      data-event-action={
        infoButton ? 'Click Quick Menu' : 'Select Vehicle Type'
      }
      data-event-label={capitalizeFirstLetter(type)}
      onClick={
        url
          ? (e) => {
              sendEventData(e);
              redirect(url);
            }
          : nextClick
          ? (e) => {
              sendEventData(e);
              nextClick(type);
            }
          : sendEventData
      }
    >
      <div className="motor">
        <div className="imageBox">
          <img src={image[type]} width={'100px'} alt="Car" />
        </div>
      </div>
      {vehicleInfo ? (
        <div className={'infoButton medium'}>
          {vehicleInfo?.regNo || vehicleForm?.regNo}
          <br />
          {vehicleInfo?.make
            ? `${vehicleInfo?.make} | ${vehicleInfo.capacity}cc`
            : null}
        </div>
      ) : (
        <div
          className={`${infoButton ? 'infoButton' : 'content'} medium font20`}
        >
          {infoButton ? t(content[type]) : t(vehicleTypeContent[type])}
        </div>
      )}
    </div>
  );
};

export default CarTypeContainer;
