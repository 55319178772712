import React from 'react';

import 'assets/styles/arrow.scss';

function AnimArrow() {
  return (
    <div className="arrow_container">
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
    </div>
  );
}

export default AnimArrow;
