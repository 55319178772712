import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';

import 'App.scss';
import 'assets/styles/styles.scss';
import { BottomFooter, Header } from 'components/Common';
import { Maintenance } from 'components/SubComponents';
// import useDisableEvents from 'hooks/DisableEvents';
import { useGoogleTagManager } from 'hooks/GoogleTagManager';
import routeJourney from 'hooks/RouteJourney';
import { listOfRoutes } from 'pages/Routes';
import setupStore from 'store/store';

function App() {
  const { persistor, store } = setupStore();
  if (
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'uat'
  ) {
    useGoogleTagManager();
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className="container">
            <Header />
            {/* {process.env.REACT_APP_ENABLED ? (
              <Routes>
                {listOfRoutes.map((item) => {
                  const RouteComponent = item.component;
                  return (
                    <Route
                      exact={item.exact}
                      key={`${item.route}`}
                      path={item.route}
                      element={
                        <RouteGuard>
                          <RouteComponent />
                        </RouteGuard>
                      }
                    />
                  );
                })}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            ) : ( */}
            <Maintenance />
            {/* )} */}
          </div>
          <BottomFooter />
        </Router>
      </PersistGate>
    </Provider>
  );
}

const RouteGuard = ({ children }) => {
  routeJourney();
  return children;
};

const PageNotFound = () => {
  return <div>Page not found</div>;
};

export default App;
