export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: '#ffffff',
    boxShadow: '0px 9px 7px 3px rgba(208, 220, 255, 0.4)',
    borderRadius: '0px 0px 20px 20px',
    padding: '40px'
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#000000',
    marginTop: '20px',
    marginBottom: '10px'
  },
  image: {
    backgroundColor: '#E7F6FF',
    borderRadius: '50%',
    padding: '10px'
  }
};
