import { useState } from 'react';

import { resetVehicleState, setFieldValue } from './VhlTypeContainer.reducer';
import { useAppDispatch } from 'hooks/ReduxHook';
import useScroll from 'hooks/ScrollTo';
import { Translate } from 'hooks/Translation';

export const VhlTypeContainerLogic = () => {
  const { scrollToElement, scrollToTop } = useScroll();
  const dispatch = useAppDispatch();
  const [openUserForm, setOpenUserForm] = useState(false);
  const { t } = Translate();
  const scrollToUserForm = (type) => {
    dispatch(setFieldValue({ key: 'VEHICLE_TYPE', value: type }));
    setOpenUserForm(true);
    scrollToElement('section2');
  };
  const closeForm = () => {
    dispatch(resetVehicleState());
    setOpenUserForm(false);
    scrollToTop();
  };
  return { scrollToUserForm, openUserForm, closeForm, t };
};

export default VhlTypeContainerLogic;
