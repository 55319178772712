/* eslint-disable camelcase */
import React from 'react';

import allianz_logo from 'assets/images/allianz_logo.svg';
import tune_logo from 'assets/images/tune_logo.svg';
import zurich_logo from 'assets/images/zurich_logo.svg';
import zurich_t_logo from 'assets/images/zurich_t_logo.svg';
import etiqa_logo from 'assets/insurer/etiqa.svg';
import rhb_logo from 'assets/insurer/rhb.svg';

function BrandList() {
  return (
    <div className="row justify-content-md-center brandlist">
      <div className="logo-container col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <ul className="d-flex justify-content-evenly">
          <li style={{ lineHeight: 6 }}>
            <img src={allianz_logo} width={'150px'} alt="Motor" />
          </li>
          <li style={{ lineHeight: 6 }}>
            <img src={rhb_logo} width={'150px'} alt="Motor" />
          </li>
          <li style={{ lineHeight: 6 }}>
            <img src={zurich_logo} width={'150px'} alt="Motor" />
          </li>
          <li style={{ lineHeight: 6 }}>
            <img src={zurich_t_logo} width={'140px'} alt="Motor" />
          </li>
          <li style={{ lineHeight: 6 }}>
            <img className="tune" src={tune_logo} width={'40px'} alt="Motor" />
          </li>
          <li style={{ lineHeight: 6 }}>
            <img
              className="etiqa"
              src={etiqa_logo}
              width={'100px'}
              alt="Motor"
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BrandList;
