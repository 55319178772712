import { useEffect, useState } from 'react';

import useWindowSize from './WindowSize';
import { defaultEnum } from 'constants/enum';

export const useMobileSize = () => {
  const { width } = useWindowSize();
  const { mobileLayoutBreakpoint } = defaultEnum;
  const [isMobileView, setIsMobileView] = useState(
    width < mobileLayoutBreakpoint
  );

  useEffect(() => {
    setIsMobileView(width < mobileLayoutBreakpoint);
  }, [width, mobileLayoutBreakpoint]);

  return { isMobileView };
};

export default useMobileSize;
