import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { setFieldValue, setFieldValue2 } from './VehicleForm.reducer';
import { setFieldValue as updateVhlType } from 'components/SubComponents/VhlTypeContainer/Logic/VhlTypeContainer.reducer';
import { vhlType } from 'constants/enum';
import DataLayer from 'hooks/DataLayerPush';
import { useAppDispatch, useAppSelector } from 'hooks/ReduxHook';
import ResumeJourney from 'hooks/ResumeJourney';
import { useStepper } from 'hooks/Stepper';
import { useCheckVehicleMutation } from 'services/api/services';
import { vixNcd } from 'services/decorator/request';
import { setVehicle } from 'store/response/vehicle';
import { setVehicleData } from 'store/response/vix';
import { hashRandom16BitItem } from 'utils/helper';

export const VehicleFormLogic = () => {
  const useFormMethod = useForm({ mode: 'all' });
  const [formError, setFormError] = useState(null);
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const divRef = useRef(null);
  const dispatch = useAppDispatch();
  const [checkVehicle, { isLoading }] = useCheckVehicleMutation();
  const [emptyMobile, setEmptyMobile] = useState(0);
  const { sendPageData } = DataLayer();
  const { status: fromInsurance, vehicleData } = ResumeJourney();
  const vehicleForm = useAppSelector((state) => state.vehicleForm);
  const { moveNext } = useStepper();

  useEffect(() => {
    if (vehicleData && fromInsurance) {
      dispatch(setFieldValue(vehicleData));
      dispatch(setFieldValue2({ key: 'email', value: vehicleData.email }));
    } else {
      setTimeout(() => {
        dispatch({ type: 'reset' });
      }, 100);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [emptyMobile]);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      if (emptyMobile === 1) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  };

  const onSubmit = async (vixData) => {
    sendPageData({
      event: 'submit_vehicle_details',
      event_category: 'Road Tax - Home',
      event_action: 'Submit Vehicle Details',
      event_label: 'Vehicle Details',
      hash_phone: hashRandom16BitItem(vixData?.mobileNumber)
    });
    dispatch(setFieldValue(vixData));
    try {
      await checkVehicle(vixNcd(vixData)).unwrap();
      setStatus('notAvailable');
    } catch (error) {
      const errorCode = error?.data?.errors?.[0]?.code;
      if (!['001', '003'].includes(errorCode)) {
        if (errorCode === '008') return setStatus('noMatch');
        setStatus('notAvailable');
      } else {
        const data = error?.data || error;
        if (data?.data) {
          const { vehCapacity, vehName, vehRegNo, vehTypeCode } = data.data;
          dispatch(
            updateVhlType({
              key: 'VEHICLE_TYPE',
              value: vhlType[vehTypeCode]
            })
          );
          dispatch(
            setVehicle({
              make: vehName,
              capacity: +vehCapacity,
              regNo: vehRegNo,
              vehTypeCode
            })
          );
          dispatch(setVehicleData(data));
        }
        moveNext();
      }
    }
  };

  const onError = (error) => {
    setFormError(error);
  };

  const showCode = () => {
    setShow(true);
  };

  return {
    setStatus,
    formError,
    onSubmit,
    onError,
    useFormMethod,
    status,
    showCode,
    show,
    divRef,
    emptyMobile,
    setEmptyMobile,
    isLoading,
    vehicleForm
  };
};

export default VehicleFormLogic;
