import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import 'assets/styles/faq.scss';
import { faq } from 'constants/faq';
import DataLayer from 'hooks/DataLayerPush';
import { useAppSelector } from 'hooks/ReduxHook';
import { Translate } from 'hooks/Translation';

export function Faq() {
  const { language } = useAppSelector((state) => state.base);
  const { t } = Translate();
  const { sendEventData } = DataLayer();
  return (
    <div className="faqContainer" id="faq">
      <div className="logo-faq medium">
        <div className="title d-flex text-left">{t('faq_title')}</div>
      </div>
      <div className="ExpandCollapse d-flex">
        <ExpandCollapse
          t={t}
          language={language}
          sendEventData={sendEventData}
        />
      </div>
    </div>
  );
}

export default Faq;

function ExpandCollapse({ language, sendEventData, t }) {
  const handleExpand = (e) => {
    const data = {
      event: 'expand_section',
      event_category: 'Road Tax - Home',
      event_action: 'Expand Section',
      event_label: e.target.textContent
    };
    sendEventData(e, data);
  };
  return (
    <>
      {faq?.length ? (
        <Accordion defaultActiveKey="0" alwaysOpen>
          {faq.map((data, i) => (
            <Accordion.Item className="item" key={i} eventKey={i}>
              <Accordion.Header
                className="header medium"
                onClick={handleExpand}
              >
                {t(data.title)}
              </Accordion.Header>
              <Accordion.Body className="body">
                {t(data.description)}{' '}
                <a href={data?.link} target="_blank" rel="noreferrer">
                  {t(data.hyperText)}
                </a>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : null}
    </>
  );
}
